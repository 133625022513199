import { ReportInfo } from "../../../../shared/reporting/api/biClient.types";
import { List, ListItem, MenuItem, Typography } from "@mui/material";

interface Props {
  reports: ReportInfo[];
  textFilter: string;
  currentReportName: string | undefined;
  onSelectNewReport: (reportId: string) => void;
}

const ReportSelectorNonGroupedList = ({ reports, textFilter, currentReportName, onSelectNewReport }: Props) => {
  const filteredReports = !textFilter
    ? reports
    : reports.filter((f) => f.name.toLowerCase().indexOf(textFilter) > -1).sort((a, b) => a.name.localeCompare(b.name));

  return (
    <List disablePadding sx={{ width: "100%" }}>
      {filteredReports.length === 0 ? (
        <ListItem sx={{ pr: 4, justifyContent: "center" }}>
          <Typography>No reports found</Typography>
        </ListItem>
      ) : (
        <>
          {filteredReports.map((gr) => (
            <MenuItem
              key={`item-${gr.name}`}
              sx={{ py: 1, justifyContent: "space-between", gap: 3 }}
              selected={currentReportName === gr.name}
              onClick={() => onSelectNewReport(gr.reportId)}
            >
              <Typography noWrap>{gr.name}</Typography>
              <Typography color="text.secondary">{gr.reportType}</Typography>
            </MenuItem>
          ))}
        </>
      )}
    </List>
  );
};

export default ReportSelectorNonGroupedList;

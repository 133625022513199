import { Grid } from "@mui/material";
import GroupSettings from "./GroupSettings";
import MeasureSettings from "./MeasureSettings";
import GeneralSettings from "./GeneralSettings";

export default function SettingsContainer() {
  return (
    <Grid container sx={{ gap: 2, flexDirection: "column", px: "1.3rem" }}>
      <GeneralSettings />
      <GroupSettings />
      <MeasureSettings />
    </Grid>
  );
}

import { List, ListItem, ListSubheader, MenuItem, Typography } from "@mui/material";
import { ReportGroup, ReportInfo } from "../../../../shared/reporting/api/biClient.types";
import { getFilteredGroupedReports } from "./reportSelectorHelpers";

interface Props {
  reports: ReportInfo[];
  clientCode: string;
  groups: Record<string, ReportGroup[]> | undefined;
  textFilter: string;
  currentReportName: string | undefined;
  onSelectNewReport: (reportId: string) => void;
}

const ReportSelectorGroupedList = ({
  groups,
  clientCode,
  reports,
  textFilter,
  currentReportName,
  onSelectNewReport,
}: Props) => {
  const filteredGroupedReports = getFilteredGroupedReports(reports, groups?.[clientCode], textFilter) || [];

  return (
    <List disablePadding sx={{ width: "100%" }}>
      {filteredGroupedReports.length === 0 && (
        <ListItem sx={{ pr: 4, justifyContent: "center" }}>
          <Typography>No reports found</Typography>
        </ListItem>
      )}
      {filteredGroupedReports.length > 0 &&
        filteredGroupedReports.map(([group, groupReports]) => (
          <ListItem key={`section-${group}`} sx={{ py: 0 }}>
            <List disablePadding sx={{ width: "100%" }}>
              <ListSubheader sx={{ py: "0.3rem", px: 0, lineHeight: "inherit" }}>
                <Typography variant="caption">{group}</Typography>
              </ListSubheader>
              {groupReports.map((gr) => (
                <MenuItem
                  key={`item-${group}-${gr.name}`}
                  sx={{ py: 1, justifyContent: "space-between", gap: 3 }}
                  selected={currentReportName === gr.name}
                  onClick={() => onSelectNewReport(gr.reportId)}
                >
                  <Typography noWrap>{gr.name}</Typography>
                  <Typography color="text.secondary">{gr.reportType}</Typography>
                </MenuItem>
              ))}
            </List>
          </ListItem>
        ))}
    </List>
  );
};

export default ReportSelectorGroupedList;
